import '@fontsource/roboto';

import React, { useState } from 'react';

import DownloadCSV from './utils/csvDownloader';
import Papa from "papaparse";

const extractEmails = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const csvData = reader.result;

      // Parse the CSV file
      Papa.parse(csvData, {
        header: false, // Don't assume headers
        skipEmptyLines: true,
        complete: (results) => {
          try {
            const emailList = [];
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            results.data.forEach((row) => {
              Object.values(row).forEach((value) => {
                if (typeof value === "string" && emailRegex.test(value.trim())) {
                  emailList.push(value.trim());
                }
              });
            });

            resolve(emailList); // Resolve the promise with the email list
          } catch (error) {
            reject(error); // Reject the promise in case of any error
          }
        },
        error: (error) => {
          reject(error); // Reject if Papa.parse encounters an error
        },
      });
    };

    reader.onerror = (error) => {
      reject(error); // Reject if FileReader encounters an error
    };

    reader.readAsText(file);
  });
};


const ValidateEmail = (email) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "OKgRkg0OZI6clEpVRPFHLfO8hgV6P5qbp0rSxvIxJM3P6mgDP30ERl9VIRR48GS4"
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://truemail.kaizenapps.com?email=${email}`, requestOptions)
      .then(async(response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const resp=await response.json()
        // console.log(resp?.success ,'-->>',resp.email)
        if(resp?.success)
          {
            
            return email
          }else{
            return null
          }
        
      })
      .then((result) => resolve(result)) 
      .catch((error) => reject(error)); 
  });
};


const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

const validateEmailsInBatches = async (emails) => {
  const batchSize = 10; 
  const emailBatches = chunkArray(emails, batchSize); 

  const results = [];
  
  for (let batch of emailBatches) {
    try {
      const batchResults = await Promise.all(batch.map(email => ValidateEmail(email)));
      results.push(...batchResults.filter(email=>email!==null)); 
    } catch (error) {
      console.error("Error in email batch:", error);
    }
  }
  return results;
};





function App() {
  const [file, setFile] = useState(null);
  const [sortMode, setSortMode] = useState('quicksort');
  const [isProcessing, setIsProcessing] = useState(false);
  
  const [validatedEmails, setValidated_emails] = useState([])

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleProcessFile = async() => {
    setIsProcessing(true);
    const method = sortMode === 'deepsort' ? 'deepsort' : 'quicksort';
    const emails=await extractEmails(file)
    
    console.log(emails)

    const validated_emails=await validateEmailsInBatches(emails)

    // console.log('validation results-->',validated_emails)
    setValidated_emails(validated_emails)
    setIsProcessing(false);



  };

  return (
    <div className=" mx-auto text-center flex flex-col items-center justify-center min-h-screen bg-blue-100 p-5">
      <h1 className="text-4xl font-bold mb-6">Kaizen Email Validator</h1>
      <div
        className={`drop-zone w-full max-w-md bg-white border-2 border-dashed border-blue-300 p-12 rounded-lg text-center transition-all duration-300 ease-in-out ${file ? '' : 'hover:bg-blue-100'}`}
        onClick={() => document.getElementById('file-input').click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <p>Drag & drop your CSV file here or click to upload.</p>
        <input
          type="file"
          id="file-input"
          name="file"
          accept=".csv"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>
      {file && (
        <div id="file-name" className="mt-4 font-bold text-blue-600">
          Uploaded File: <span>{file.name}</span>
        </div>
      )}
      <div className="toggle-switch-container mt-8 flex flex-col items-center">
        <div className="toggle-label font-bold mb-2">Turbo or Deep Validate Mode</div>
        <div className="toggle-switch flex items-center gap-4">
          <span>⚡</span>
          <div className="relative inline-block w-14 h-8">
            
            <span onClick={()=>setSortMode(sortMode === 'quicksort' ? 'deepsort' : 'quicksort')} className={`slider block absolute top-0 left-0 right-0 bottom-0 bg-gray-300 transition-all duration-300 rounded-full before:content-[''] before:absolute before:h-6 before:w-6 before:left-1 before:bottom-1 before:bg-white before:rounded-full before:transition-all before:duration-300 ${sortMode === 'deepsort' ? 'bg-green-500' : ''} ${sortMode === 'deepsort' ? 'before:translate-x-6' : ''}`}></span>    </div>
          <span>🔍</span>
        </div>
      </div>
      <button
        onClick={handleProcessFile}
        className={`neomorphic-button mt-8 font-bold py-4 px-10 rounded-lg transition-shadow duration-300 ${file ? 'bg-green-500 text-white cursor-pointer' : 'bg-gray-400 text-gray-200 cursor-not-allowed'}`}
        disabled={!file}
      >
        Validate
      </button>
      {isProcessing && (
        <div id="progress" className="mt-6">
          <p>Processing...</p>
        </div>
      )}
      {validatedEmails?.length>0 && (
        <div id="download" className="mt-6">
         <DownloadCSV emails={validatedEmails}/>
        </div>
      )}
    </div>
  );
}

export default App;

/* Tailwind CSS classes have been added to replace inline styles */
