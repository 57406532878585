import Papa from "papaparse";
import React from "react";
import { saveAs } from "file-saver";

const DownloadCSV = ({ emails }) => {
  const handleDownload = () => {
   
    const csvData = emails.map((email) => ({ "Valid Emails": email }));

   
    const csv = Papa.unparse(csvData);

   
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "valid_emails.csv");
  };

  return (
    <button
      onClick={handleDownload}
      style={{
        padding: "10px 20px",
        backgroundColor: "#007BFF",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Download CSV
    </button>
  );
};

export default DownloadCSV;
